import React, { useState, useEffect } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { Button, Table, Form, notification, Modal, Input, Select, Space, FloatButton } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import getFiltersInColumn from '../../utils/filteredColumns';
import { isMobile } from 'react-device-detect';
import FeatureModal from '../OptionsFeatureModal/FeatureModal';
import SearchField from '../SearchField/SearchReqUsers';
import { SyncOutlined }from '@ant-design/icons';




const UsersRequestTable = () => {

  const { request } = useHttp();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [value, setValue] = useState();
  const [searcher, setSearcher] = useState(false);

  const columns = isMobile ?
    [
      {
        title: 'Информация',
        dataIndex: 'user_info',
        render: (text, record) => {
          return (
            <Space direction='vertical'>
              {record.name}
              {record.sub_id_name}
            </Space>
          )
        }
      },
      {
        title: 'Действия',
        render: (text, record) => {
          return (
            <Space direction='vertical'>
              <LocalizedModalOk name={record.name} />
              <FeatureModal name={record.name}
                email={record.email}
                tel={record.tel}
                sub_id_name={record.sub_id_name} />
              <LocalizedModalCancel name={record.name} />
            </Space>
          )
        }
      }
    ]
    :
    [
      {
        title: 'Позывной',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        render: (text, record) => { return record.name }
      },
      {
        title: 'Логин',
        dataIndex: 'email',
        key: 'email',
        width: 150,
        render: (text, record) => { return record.email }
      },
      {
        title: 'Телефон/Telegram',
        dataIndex: 'tel',
        key: 'tel',
        width: 150,
        render: (text, record) => { return record.tel }
      },
      {
        title: 'Ответственный',
        dataIndex: 'sub_id_name',
        key: 'sub_id_name',
        width: 150,
        render: (text, record) => record.sub_id_name
      },
      {
        title: 'Действия',
        dataIndex: 'action',
        key: 'action',
        width: 50,
        fixed: 'right',
        render: (text, record) => {
          return (
            <div style={{textAlign: 'center'}}>
              <Space>
                <LocalizedModalOk name={record.name} />
                <LocalizedModalCancel name={record.name} />
              </Space>
            </div>
          )
        },
      }
    ];

  const LocalizedModalOk = (params) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      toApprove(params.name);
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
      <>
        <Button type="primary" size={isMobile ? 'small' : ''}  style={{width: '100%', position: 'relative'}} onClick={showModal}>
          {isMobile ? 'Подтвердить' : <CheckOutlined />}
        </Button>
        <Modal title="Подтвердите действие" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <p>Вы уверены что хотите подтвердить эту заявку?</p>
        </Modal>
      </>
    );
  };

  const LocalizedModalCancel = (params) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      toCancelled(params.name);
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
      <>
        <Button type="primary" size={isMobile ? 'small' : ''} style={{width: '100%', position: 'relative'}} onClick={showModal} danger>
          {isMobile ? 'Отменить' : <CloseOutlined />}
        </Button>
        <Modal title="Подтвердите действие" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <p>Вы уверены что хотите отклонить эту заявку?</p>
        </Modal>
      </>
    );
  };

  const toApprove = async (name) => {
    try {
      await request('/api/new_user_request/approved', 'POST', {
        name: name
      });
      fetchListUsersTable();
    } catch (err) {
      console.log('ERR: ', err);
    }
  }

  const toCancelled = async (name) => {
    try {
      await request('/api/new_user_request/cancelled', 'POST', {
        name: name
      });
      fetchListUsersTable();
    } catch (err) {
      console.log('ERR: ', err);
    }
  }

  //* GET ALL USERS
  const fetchListUsersTable = async () => {
    try {
      setLoading(true);
      const offset = page * pageSize; 
      request(`api/new_user_request/user_registered?offset=${offset}`)
        .then((resData) => {
          setTotalData(resData.count || 0);
          setData(resData.rows.map((el, i) => ({ ...el, key: i + 1 })));
          // console.log('resdata', resData)
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.log('error-fetchDataChartPage >>>', error);
    }
  };

  useEffect(() => {
    fetchListUsersTable();
  }, [page]);

  const onFinish = async (values) => {
    try {
      request('/api/new_user_request/user_registered');
      await fetchListUsersTable();
      // form.resetFields();
      setPage(1); 
    } catch (error) {
      console.log('error-getDeleteCell >>>', error, error.message);
    }
  };

  // console.log(page, searcher)
  return (
    <>
      <div className='regreq_pos'>
      <SearchField
          setValue={setValue}
          setTotalData={setTotalData}
          setData={setData}
          setSearcher={setSearcher}
          setSearchValue={setSearchValue}
          fetchList={fetchListUsersTable}
        />
        <Form form={form} onFinish={onFinish}>
          <Table
            loading={loading}
            columns={columns}
            dataSource={data}
            current={page}
            total={totalData}
            pageSize={pageSize}
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: false,
              current: page,
              pageSize: pageSize,
              total: totalData,
              onChange: async (page, pageSize) => {
                setLoading(true);
                setPage(page);
                request(`api/new_user_request/user_registered`)
                  .then((res) => {
                    setData(res.rows.map((el, i) => ({ ...el, key: i + 1 })));
                  })
                  .finally(() => setLoading(false))
              },
            }}
            scroll={isMobile ? {} : {
              x: 2500
            }}
            style={{ marginTop: '4%' }}
            bordered
          />
        </Form>
       {searcher ? <FloatButton
        icon={<SyncOutlined />}
        tooltip={`Очистить фильтр`}
        onClick={() => {
          setSearcher(false)
          setPage(1)
          fetchListUsersTable();
          value(null)
        }}/> : null}
      </div>
    </>
  );

}

export default UsersRequestTable;
