import React from 'react';
import { Modal, Form, Input, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const UploadModal = ({ visible, onCancel, onCreate, fileList, propsCreate, uploading }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      open={visible}
      title="Загрузить файл"
      okText={uploading ? 'Загрузка...' : 'Создать'}
      cancelText="Отмена"
      onCancel={onCancel}
      type="primary"
      disabled={fileList.length === 0}
      loading={uploading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
        encType="multipart/form-data"
      >
        <Form.Item
          name="filename"
          label="Имя файла"
          rules={[
            {
              required: true,
              message: 'Это поле не может быть пустым!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="path"
          rules={[
            {
              required: true,
              message: 'Это поле не может быть пустым!',
            },
          ]}
        >
          <Upload {...propsCreate} name="path">
            <Button icon={<UploadOutlined />}>Выбрать файл</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UploadModal;
