import React, { useEffect, useState } from 'react';
import { Button, Form, message, Input, Modal, Select, DatePicker } from 'antd';
import { useHttp } from '../../hooks/http.hook';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

const GenerateNewIdModal = (params) => {
  const { fetchListDevices, actualSegment, userInfo, isGenerateIdOpen, setGenerateIdOpen, searchValue, setSearchValue } = params;
  const { request } = useHttp();
  const [value, setValue] = useState([]);
  const [form] = Form.useForm();
  const [isFormValid, setIsFormValid] = useState(false);

  const [data, setData] = useState([]);

  dayjs.extend(utc);

  useEffect(() => {
    form.setFieldsValue({ device_id: searchValue });
  }, [isGenerateIdOpen, searchValue]);

  const currentDate = dayjs.utc().minute(0).second(0).millisecond(0);
  const nextYearDate = dayjs(currentDate).add(180, 'days');

  const showModal = () => {
    setGenerateIdOpen(true);
  };

  const handleCancel = () => {
    setGenerateIdOpen(false);
    setSearchValue('');
    form.resetFields();
  };

  // active OK button
  const checkFormValidity = () => {
    const values = form.getFieldsValue();
    const requiredFieldsFilled = values.device_id && values.call_sign && values.army_post && values.type;
    setIsFormValid(requiredFieldsFilled);
  };

  const fetchListUsersTable = async () => {
    try {
      const resData = await request(`/api/users/getUsersChats`);
      return setData(resData.rows.map((el, i) => ({...el, key: i + 1}))
        .filter((el) => el.id !== userInfo.userId))
    } catch (error) {
      console.log('error-fetchDataChartPage >>>', error);
    }
  };

  const onFinish = async (values) => {
    try {
      const license_expires = values.license_expires.minute(0).second(0).millisecond(0);
      const resDeviceData = await request(`/api/device`, 'POST', {
        user_id: userInfo.userId,
        device_id: values.device_id,
        call_sign: values.call_sign,
        army_post: values.army_post,
        type: values.type.value,
        sub_id: values.sub_id ? values.sub_id.value : null,
        tg: values.tg || null,
        license_expires: license_expires,
      });

      if (!resDeviceData.error) {
        message.success('Устройство успешно добавлено!');
      } else {
          message.error('Не удалось добавить устройство!');
      }
      fetchListDevices(actualSegment);
      setGenerateIdOpen(false);
    } catch (error) {
      console.log('ERROR - onGenerateNewIdModal - forwarder>>>', error);
      if (error.message === 'Validation error') {
        message.info('ОШИБКА создания! Такой агент уже существует.');
      }
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal} style={{ float: 'right', marginBlock: 10 }}>
        Сгенерировать ID
      </Button>
      <Modal
        title="Добавление нового устройства"
        open={isGenerateIdOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Отмена
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              form.validateFields()
                .then((values) => {
                  form.resetFields();
                  setSearchValue('');
                  onFinish(values);
                })
                .catch((info) => {
                  console.log('Validate failed: ', info);
                });
            }}
            disabled={!isFormValid}
          >
            OK
          </Button>
        ]}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          form={form}
          autoComplete="off"
          onValuesChange={checkFormValidity}
        >
          <Form.Item
            label="Android ID"
            name="device_id"
            initialValue={searchValue}
            rules={[{ required: true, message: 'Необходимо указать ID устройства!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Позывной"
            name="call_sign"
            rules={[{ required: true, message: 'Необходимо указать позывной!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Военная часть"
            name="army_post"
            rules={[{ required: true, message: 'Необходимо указать военную часть!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Тип устройства"
            name="type"
            rules={[{ required: true, message: 'Необходимо указать тип устройства!' }]}
          >
            <Select
              labelInValue
              style={{ width: '100%' }}
              options={[
                { value: 'tablet', label: 'Планшет' },
                { value: 'panel', label: 'Пульт' },
                { value: 'router', label: 'Маршрутизатор' },
              ]}
            />
          </Form.Item>
 
          <Form.Item label="Пользователь" name="sub_id" onClick={fetchListUsersTable}>

            <Select
              showSearch
              mode='multiple'
              placeholder="Выберите пользователя"
              optionFilterProp="children"
              value={value}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={data?.map(({id, name, email}) => ({value: id, label: name || email}))}
              onChange={(newValue) => setValue(newValue)}
            />
          
          </Form.Item> 
          
          <Form.Item label="Телефон/Telegram" name="tg">
            <Input />
          </Form.Item>

          <Form.Item label="Лицензия истекает:" name="license_expires" initialValue={nextYearDate}>
            <DatePicker />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default GenerateNewIdModal;