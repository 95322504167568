import { useState, useCallback, useEffect } from 'react';
import DirectoryStore from '../store/DirectoryStore';
import { useHttp } from './http.hook';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../store/userSlice';
// import { io } from 'socket.io-client';

const storageName = 'userData';
// const socket = io.connect(process.env.REACT_APP_API_URL);

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [role, setRole] = useState(null);
  const dispatch = useDispatch();
  const { request } = useHttp();

  const login = useCallback(async (jwtToken, id = 0, role) => {
    setToken(jwtToken);
    setUserId(id);

    localStorage.setItem(
      storageName,
      JSON.stringify({
        userId: id,
        token: jwtToken,
      })
    );
    console.log('JWT ', jwtToken)
    dispatch(fetchUser());

    // socket.on('connection', () => {
    //   console.log('connect');
    // });

    /* -------------- получение справочников -----------------------------------------------*/
    /* ранее использовалось для получения справочников при логине, так как 
       была необходимость попадать на mainTable со страницы авторизации по прямму пути
       localhost:3000/main_table, но в последствии было принято решение, что после логина 
       пользователь попадает на главную страницу и далее использует навигацию, соответственно
       пропала необходимость на данные запросы */

    // const resForwarder = await request('api/forwarder', 'GET', null, {
    //   Authorization: `Bearer ${jwtToken}`,
    // });
    // DirectoryStore.setDirectory(resForwarder, 'forwarders');

    // const resAgent = await request('api/agent', 'GET', null, {
    //   Authorization: `Bearer ${jwtToken}`,
    // });
    // DirectoryStore.setDirectory(resAgent, 'agents');

    // const resFinalDistination = await request(
    //   'api/final-destination',
    //   'GET',
    //   null,
    //   {
    //     Authorization: `Bearer ${jwtToken}`,
    //   }
    // );
    // DirectoryStore.setDirectory(resFinalDistination, 'finalDistination');

    // const resWaypoint = await request('api/waypoint', 'GET', null, {
    //   Authorization: `Bearer ${jwtToken}`,
    // });
    // DirectoryStore.setDirectory(resWaypoint, 'waypoint');

    // const resMethod = await request('api/sending-method', 'GET', null, {
    //   Authorization: `Bearer ${jwtToken}`,
    // });
    // DirectoryStore.setDirectory(resMethod, 'method');

    // const resFilename = await request('api/list/full/filenames', 'GET', null, {
    //   Authorization: `Bearer ${jwtToken}`,
    // });
    // DirectoryStore.setFilename(resFilename);
    /* -------------- получение справочников конец----------------------------------------*/
  }, []);

  const logout = useCallback(() => {
    const userData = localStorage.getItem('userData');
    // let id = null;
    // if (userData) {
    //   id = JSON.parse(userData).userId;
    // }
    // socket.emit(
    //   'disconnectUser',
    //   {
    //     userId: id,
    //   },
    //   (response) => {
    //     console.log(response);
    //   }
    // );

    // localStorage.removeItem(storageName);
    // localStorage.removeItem('userfield');
    // localStorage.removeItem('userFilename');
    // localStorage.removeItem('userfieldContainer');
    // localStorage.removeItem('forwarderFields');
    // localStorage.removeItem('limitPagination');
    // localStorage.removeItem('sparePartsFilename');
    // localStorage.removeItem('filterRespForw');
    localStorage.clear();
    setToken(null);
    setUserId(null);
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName));

    if (data && data.token) {
      login(data.token, data.userId, data.role);
    }
  }, [login]);

  return { login, logout, token, userId, role };
};
