import React, { useState } from 'react';
import { Button, Progress } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import UploadModal from '../../../components/UploadModal/UploadModal';

const CreateForm = ({ type, fetchListChartPage }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progressState, setProgressState] = useState(0);
  const userData = localStorage.getItem('userData');
  // const { getAllFields } = params;g
  let token = null;
  if (userData) {
    token = JSON.parse(userData).token;
  }

  const propsCreate = {
    accept: '.hgt',
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      console.log('newFileList', newFileList);
      return setFileList(...fileList, newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const onCreate = async (values) => {
    try {
      console.log('Received values of form: ', values);
      const user_id = JSON.parse(localStorage.userData).userId;

      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append('file', file);
        formData.append('raw_path', values.filename);
        formData.append('user_id', user_id);
        formData.append('target', type);
      });

      setUploading(true);

      const response = new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.open('POST', 'api/app/map/upload');
        xhr.responseType = 'text';
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);

        xhr.upload.onload = async () => {
          if (xhr.status >= 400) {
            reject(xhr.response);
          } else {
            resolve(xhr.response);
            setFileList([]);
            setUploading(false);
            setModalVisible(false);
            fetchListChartPage();
          }
        }

        xhr.upload.onprogress = (event) => {
          setProgressState(+Math.floor((event.loaded / event.total) * 100));
        }

        xhr.upload.onerror = () => {
          reject(xhr.response);
        }
        xhr.send(formData);
      });
    } catch (err) {
      console.log('ERR: onCreate >>> ', err);
    }
  };

  return (
    <>
      <div className='ChartPage__header'>
        {uploading ? (
          <Progress type="circle" percent={progressState} size={80} />
        ) : (
          <Button
            type='primary'
            size='small'
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setModalVisible(true);
            }}
          >
            Добавить поле
          </Button>
        )}
        <UploadModal
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onCreate={onCreate}
          fileList={fileList}
          propsCreate={propsCreate}
          uploading={uploading}
        />
      </div>
    </>
  );
};

export default CreateForm;