import React, { useEffect, useState, useRef } from 'react';
import { Layout, List, Avatar, Button, Input, Modal, Typography, Divider, Upload, message, Popconfirm, Spin, notification } from 'antd';
import { emit, on, removeAllListeners } from "../../socket";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { PaperClipOutlined, SendOutlined, FileOutlined, DeleteOutlined, PhoneOutlined } from '@ant-design/icons';
import StartNewChatModal from "../startNewChatModal/StartNewChatModal";
import { useHttp } from '../../hooks/http.hook';
import { setShowUnreadIndicator } from '../../store/uiSlice';
import { resetUnreadMessages } from '../../store/unreadMessagesSlice';
import { useLocation } from 'react-router-dom';

const { Header, Sider, Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;

const ChatComponent = () => {
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [chatName, setChatName] = useState('');
  const [chatParticipants, setChatParticipants] = useState('');
  const [actualChat, setActualChat] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const userInfo = useSelector((state) => state.userInfo.userState);
  const unreadMessages = useSelector((state) => state.unreadMessages);
  const messagesEndRef = useRef(null);
  const [textMessage, setTextMessage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [participantModalVisible, setParticipantModalVisible] = useState(false);
  const { request } = useHttp();
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState('');
  const [newMessage, setNewMessage] = useState(0);
  const [fullArrayMessages, setFullArrayMessages] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [subs, setSubs] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [chatForSubs, setChatForSubs] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const chatFromUrl = location.search.split('?')[1];
 
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (chatFromUrl) {
      handleChatClick(chatFromUrl);
    }
  }, [chatFromUrl]);

  useEffect(() => {
    const handleNewChat = () => {
      fetchActualChats();
    };
  
    const handleNewMessage = async (data) => {
      if (data.status === 'new_message') {
        if (`${actualChat}` === `${data.chatID}`) {
          try {
            await fetchChatInfo(data.chatID, messages?.length + 1);
            dispatch(setShowUnreadIndicator(false));
          } catch (error) {
            console.error('Error fetching chat info:', error);
          }
        }
        setNewMessage((prevNewMessage) => prevNewMessage + 1);
      }
    };
  
    on('new_chat', handleNewChat);
    on('message_v_0.4', handleNewMessage);
  
    return function cleanup() {
      removeAllListeners('message_v_0.4');
      removeAllListeners('new_chat');
    };
  }, [actualChat, messages]);

  useEffect(() => {
    fetchActualChats();
    setNewMessage(newMessage + 1);
  }, [actualChat]);

  useEffect(() => {
    if (newMessage !== 0) {
      scrollToBottom();
    }
  }, [newMessage]);

  useEffect(() => {
    if (subs?.length > 0) {
      setParticipantModalVisible(true);
    }
  }, [subs]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const fetchChatInfo = async (chat, limit) => {
    try {
      const data = await request(`/api/message/getChatMessages?chatID=${chat}&limit=${limit}`);
      if (actualChat !== chat) {
        setActualChat(chat);
      }
      setMessages(data.chatInfo.messages.reverse());
      setChatName(data.chatInfo.chatName);
      setChatParticipants(data.chatInfo.chatAndSubs);
      setFullArrayMessages(data.fullArrayMessages);
      dispatch(resetUnreadMessages({ chatId: chat }));
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchMoreMessages = async (chat) => {
    setNewMessage(0);
    await fetchChatInfo(chat, messages?.length + 30);
  };

  const fetchActualChats = async () => {
    try {
      const data = await request(`/api/message/getChats?userId=${userInfo.userId}`);
      setChats(data);
      if (data.length) {
        for (const i of data) {
          emit('join-room', `chat-${i.id}`);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const getSenderName = (message, chatSubs) => {
    if (`${message.senderID}` === `${userInfo.userId}`) {
      return 'Вы';
    }
    for (const item of chatSubs) {
      if (item.users && `${message.senderID}` === `${item.users[0].id}`) {
        return item.users[0].name || item.users[0].email;
      }
    }
    return 'Пользователь ' + message.senderID;
  };

  const fetchSubs = async (chat) => {
    try {
      const data = await request(`/api/message/getChatSubs?chatId=${chat}`);
      const isAdminResponse = await fetch(`/api/message/checkAdmin?&user=${userInfo.userId}&chat=${chat}`);
      const adminData = await isAdminResponse.json();
      if (adminData.error) {
        throw new Error(adminData.error);
      }
      setIsAdmin(adminData.admin);
      setSubs(data[0].users);
      setChatForSubs(chat);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendMessage = async () => {
    const updatedMessages = [...messages];
  
    if (textMessage.trim() || fileList.length > 0) {
      const formData = new FormData();
      formData.append('senderID', userInfo.userId);
      formData.append('type', fileList.length > 0 ? 'file' : 'text');
      formData.append('text', textMessage);
      formData.append('chatID', actualChat);
  
      if (fileList.length > 0) {
        formData.append('file', fileList[0].originFileObj);
        formData.append('originalFileName', fileList[0].name);
      }
  
      try {
        setIsFileLoading(true);
        await fetch('/api/message/send_v4', {
          method: 'POST',
          body: formData,
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.userData).token}`
          }
        });
  
      } catch (error) {
        console.log(error);
      }
  
      updatedMessages.push({
        senderID: userInfo.userId,
        type: fileList.length > 0 ? 'file' : 'text',
        text: textMessage,
        chatID: actualChat,
        ...(fileList.length && { originalFileName: fileList[0].name })
      });
  
      setMessages(updatedMessages);
  
      setIsFileLoading(false);
      setTextMessage('');
      setFileList([]);
    }
  };
  
  const handleSendOnlyText = async () => {
    const updatedMessages = [...messages];
  
    if (textMessage.trim()) {
      const formData = new FormData();
      formData.append('senderID', userInfo.userId);
      formData.append('type', 'text');
      formData.append('text', textMessage);
      formData.append('chatID', actualChat);
  
      try {
        await fetch('/api/message/send_v4', {
          method: 'POST',
          body: formData,
          headers: {
            authorization: `Bearer ${JSON.parse(localStorage.userData).token}`,
          }
        });
  
      } catch (error) {
        console.log(error);
      }
  
      updatedMessages.push({
        senderID: userInfo.userId,
        type: 'text',
        text: textMessage,
        chatID: actualChat,
      });
  
      setMessages(updatedMessages);
      setTextMessage('');
    }
  };

  const handleChatClick = (chatId) => {
    setSelectedChatId(chatId);
    fetchChatInfo(chatId, 30);
    setTextMessage('');
    dispatch(setShowUnreadIndicator(false));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    setIsModalVisible(false);
    if (fileList.length > 0) {
      await handleSendMessage();
    }
  };

  const handleModalCancel = () => {
    setFileList([]);
    setIsModalVisible(false);
  };

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const onSearch = async (value) => {
    if (value.length) {
      setValue(value);
      const response = await request(`/api/message/find_with?text=${value}&userId=${userInfo.userId}`);

      if (response.error) {
        message.error('Соответствий не найдено!');
      } else {
        if (response.length > 0) {
          setChats(response);
          setInputValue('');
        } else {
          message.error('Ничего не найдено!');
        }
      }
    }
  };

  const handleChatDelete = async (e, chat, user) => {
    e.stopPropagation();
    await request(`/api/message/delete_chat?chat=${chat}&user=${user}`, 'DELETE');
    await fetchActualChats();
    setActualChat(null);
    setSelectedChatId(null);
    setMessages([]);
  };

  const handleMemberDelete = async (e, member, user, chat) => {
    e.stopPropagation();
    await request(`/api/message/deleteMember?memberId=${member.id}&user=${user}&chat=${chat}`, 'DELETE');
    const newSubs = subs.filter(sub => sub.id !== member.id);
    setSubs(newSubs);
    api.info({
      message: 'Удаление участника',
      description: `Участник ${member.name || member.email} удален из чата ${chatName}`,
      placement: "topRight",
    })
  };


  return (
    <>
    {contextHolder}
    <Layout style={{ minHeight: 0, height: '77vh', width: '80vw', margin: '7vh auto', borderRadius: '20px' }}>
      <Sider width={'20vw'} style={{ background: '#fff', borderRadius: '20px' }}>
        <Header style={{ background: '#fff', padding: 0, height: "60px", borderRadius: '20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 16px' }}>
            <Text strong style={{padding:'15px 0 0 0', fontSize: '1.4em'}}>Чаты</Text>
            <StartNewChatModal userInfo={userInfo} />
          </div>
        </Header>
        <Divider style={{ margin: 0, borderWidth: 5 }} />
        <Header style={{ background: '#fff', padding: 0, height: "50px", borderRadius: '20px' }}>
          <div style={{margin: '15px 15px'}}>
            <Input.Search
              placeholder="Поиск чата" 
              allowClear
              enterButton="Найти"
              onSearch={onSearch}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
        </Header>
        <Divider style={{ margin: 0, borderWidth: 5 }} />
        <List
          itemLayout="horizontal"
          dataSource={chats}
          size='small'
          style={{overflow: 'auto', height: '60.5vh'}}
          renderItem={(item) => (
            <List.Item
              onClick={() => handleChatClick(item.id)}
              style={{
                cursor: 'pointer',
                transition: 'background 0.3s',
                backgroundColor: selectedChatId === item.id ? '#cdf' : '#fff'
              }}
              onMouseEnter={(e) => {
                if (selectedChatId !== item.id) {
                  e.currentTarget.style.background = '#f0f0f0';
                }
              }}
              onMouseLeave={(e) => {
                if (selectedChatId !== item.id) {
                  e.currentTarget.style.background = '#fff';
                }
              }}
            >
              <List.Item.Meta
                avatar={
                  <div style={{position: 'relative'}}>
                  <Avatar>
                    {item.chatName ? item.chatName.charAt(0) : '?'}
                  </Avatar>
                  {unreadMessages[item.id] > 0 && (
                    <div style={{backgroundColor: 'blue', width: '24px', height: '24px', position: 'absolute', bottom: '17px', right: '-7px', textAlign: 'center', borderRadius:'100px'}}>
                      <span style={{ color: 'white', fontSize: '10px', fontWeight: 'bold' }}>{unreadMessages[item.id]}</span>
                    </div>
                    )}
                  </div>
                }
                title={
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p style={{ marginTop: '5px', overflow: 'hidden', marginBottom: '0' }}>{item.chatName ? item.chatName : '?'}</p>
                  </div>
                }
              />
              <Popconfirm
                title="Удаление чата"
                description="Вы уверены что хотите удалить чат из списка?"
                onConfirm={(e) => handleChatDelete(e, item.id, userInfo.userId)}
                okText="Удалить"
                cancelText="Отмена">
                <Button onClick={(e) => e.stopPropagation()} icon={<DeleteOutlined />}/>
              </Popconfirm>
            </List.Item>
          )}
        />
      </Sider>
      <Layout style={{ marginLeft:'10px', borderRadius: '20px', minHeight:0, height: '77vh' }}>
        <Header style={{ background: '#fff', padding: 0, height: "60px", borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
          {actualChat !== null && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <>
                <Text strong style={{padding:'15px', fontSize: '1.4em'}}>{chatName}</Text>
              </>
              <div>
                {isFileLoading === false || <Spin style={{marginRight: '10px'}} />}
                <Button style={{ marginRight: '15px', marginTop: '15px'}} onClick={() => fetchSubs(actualChat)}>Список участников</Button>
              </div>
            </div>
          )}
        </Header>
        <Divider style={{ margin: 0, borderWidth: 5, background: '#fff' }} />
        <Content style={{ display: 'flex', flexDirection: 'column', padding: '16px', background: '#fff', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', height: '50vh' }}>
          <div style={{ flex: 1, overflowY: 'auto' }}>
            {fullArrayMessages === false && actualChat && <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
              <Button style={{display: 'inline-block', margin: '0 auto'}} onClick={() => fetchMoreMessages(actualChat)}>Загрузить сообщения выше</Button>
            </div>}
            <List
              itemLayout="vertical"
              dataSource={messages}
              renderItem={(item) => {
                const isUserMessage = `${item.senderID}` === `${userInfo.userId}`;
                // console.log(item)
                return (
                  <List.Item style={{ border: 'none', padding: '0' }}>
                    <List.Item.Meta
                      description={
                        <div
                          style={{
                            marginLeft: isUserMessage ? '0' : '20px',
                            marginRight: isUserMessage ? '20px' : '0',
                            textAlign: isUserMessage ? 'right' : 'left',
                          }}
                        >
                          <Text style={{fontSize: '12px'}} type="secondary">{getSenderName(item, chatParticipants)}:</Text>
                          <br />
                          {item.type === 'file' ? (
                            <a href={`/api/message/file/downloadFile?name=${item.url}`}>
                              <Button icon={<FileOutlined style={{height: '30px', width: '30px', fontSize: '1.1vw'}}/>} type="link">
                                <p style={{display: 'inline-block', fontSize: '1.1vw', textDecoration: 'underline' }}>{item.url ? item.url.slice(item.url.indexOf('-') + 1) : 'file'}</p>
                              </Button>
                            </a>
                          ) : (
                            <Text style={{ display: 'inline-block', fontSize: '1.1vw', padding: '10px 15px', borderRadius: '10px', maxWidth: '85%', background: isUserMessage ? '#cdf' : '#f0f0f0' }}>
                              {item.text}
                            </Text>
                          )}
                          <br />
                          <Text style={{fontSize: '10px'}} type="secondary">{moment(item.updatedAt).locale('ru').format('DD.MM.YYYY HH:mm')}</Text>
                        </div>
                      }
                    />
                  </List.Item>
                );
              }}
            />
            <div ref={messagesEndRef} />
          </div>
          {actualChat !== null && (
            <div style={{ display: 'flex', margin: '10px 20px 0', height: '50px'}}>
              <Button 
                style={{ height: '50px', width: '50px', fontSize: '19px' }} 
                icon={<PaperClipOutlined />} 
                onClick={showModal}
              />
              <TextArea
                rows={1}
                style={{ height: '50px', resize: 'none',  marginLeft: '10px', flex: 1, fontSize: '1.1vw' }}
                placeholder="Сообщение..."
                value={textMessage}
                onChange={(e) => setTextMessage(e.target.value)}
                onPressEnter={handleSendOnlyText}
              />
              <Button type="primary" icon={<SendOutlined />} style={{ marginLeft: '10px', height: '50px', width: '50px', fontSize: '17px' }} onClick={handleSendOnlyText} /> 
            </div>
          )}
        </Content>
      </Layout>
      <Modal 
        title="Прикрепить файл" 
        open={isModalVisible} 
        onOk={handleModalOk} 
        onCancel={handleModalCancel}
        okText="Прикрепить"
        cancelText="Отмена"
      >
        <Upload
          beforeUpload={() => false}
          maxCount={1}
          onChange={handleFileChange}
          fileList={fileList}
          onRemove={() => setFileList([])}
        >
          <Button icon={<PaperClipOutlined />}>Выбрать файл</Button>
        </Upload>
      </Modal>
      <Modal
        title={`Участники беседы ${chatName}:`}
        open={participantModalVisible}
        onOk={() => setParticipantModalVisible(false)}
        onCancel={() => setParticipantModalVisible(false)}
        footer={null}
        width={'450px'}
      >
        <div style={{ overflow: 'auto', height: '25vh', marginTop: '20px' }}>
          {subs && subs.map((item) => <div key={item.email} style={{ display: 'flex', justifyContent: 'flex-start', height: '40px' }}>
            {isAdmin && <Popconfirm
              title="Удаление участника"
              description="Вы уверены что хотите удалить участника из чата?"
              onConfirm={(e) => handleMemberDelete(e, item, userInfo.userId, actualChat)}
              okText="Удалить"
              cancelText="Отмена">
              <Button disabled={userInfo.name === item.name} style={{ width: '16px', height: '18px', border: '0', marginTop: '6px' }} onClick={(e) => e.stopPropagation()} icon={<DeleteOutlined />} />
            </Popconfirm>}
            <p style={{ paddingTop: "4px", marginLeft: '7px' }}>
              {item.name || item.email}
            </p>
          </div>)}
        </div>
      </Modal>
    </Layout>
    </>
  );
};

export default ChatComponent;
