import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Badge } from 'antd';
import './index.css';

import styles from './index.module.css';

import { isMobile } from 'react-device-detect';

import {
  LogoutOutlined,
  AppstoreOutlined,
  SnippetsOutlined,
  ClockCircleOutlined,
  PlayCircleOutlined,
  TeamOutlined,
  SolutionOutlined,
  ReadOutlined,
  UsergroupAddOutlined,
  MenuOutlined,
  BookOutlined,
  AreaChartOutlined,
  MessageOutlined,
  BorderOuterOutlined,
  AuditOutlined,
  VideoCameraAddOutlined,
  DesktopOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { AuthContext } from '../../context/AuthContext';
import DrawerProfile from '../DrawerProfile/DrawerProfile';

const NavBar = () => {
  const auth = useContext(AuthContext);
  const { isAdmin, userState, status } = useSelector((state) => state.userInfo);
  const showUnreadIndicator = useSelector((state) => state.ui.showUnreadIndicator);
  const translationGroup = useSelector((state) => state.userInfo.translationGroup);
  const [isDot, setIsDot] = useState(false);

  useEffect(() => {
    if (typeof translationGroup === 'string') {
      setIsDot(true);
    } else {
      setIsDot(false);
    }
  }, [translationGroup]);

  const logoutHandler = (event) => {
    event.preventDefault();
    auth.logout();
    window.location.reload();
  };

  const items = [
    {
      key: 'forwarder',
      label:(
        <span className={styles.NavContainer}>
        <SnippetsOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
          <NavLink to="/directory/forwarder" className={styles.NavHover}>
            Список устройств
          </NavLink>
      </span>
      ),
    },
    {
      key: 'messages',
      label:(
        <span className={styles.NavContainer}>
          <MessageOutlined className={styles.NavHover}/>
          <Badge dot={showUnreadIndicator}>
            <NavLink to="/messages" className={styles.NavHover}>
              Сообщения
            </NavLink>
          </Badge>
        </span>
      ),
    },
    {
      key: 'app',
      label:(
        <span className={styles.NavContainer}>
          <AppstoreOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/app" className={styles.NavHover}>Приложения</NavLink>
        </span>
      ),
    },
    {
      key: 'geo_data',
      label:(
        <span className={styles.NavContainer}>
          <BorderOuterOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/geo" className={styles.NavHover}>Геоданные</NavLink>
        </span>
      ),
    },
    {
      key: 'maps',
      label:(
        <span className={styles.NavContainer}>
          <ReadOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/map" className={styles.NavHover}>Карты</NavLink>
        </span>
      ),
    },
    {
      key: 'registration_requests',
      label:(
        <span className={styles.NavContainer}>
          <ClockCircleOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/registered" className={styles.NavHover}>Запросы</NavLink>
        </span>
      ),
    },
    {
      key: 'translation',
      label:(
        <span className={styles.NavContainer}>
          <PlayCircleOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/translation" className={styles.NavHover}>
              Трансляция
            </NavLink>
        </span>
      ),
    },
    {
      key: 'udp_translation',
      label:(
        <span className={styles.NavContainer}>
          <VideoCameraAddOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/udp_translation" className={styles.NavHover}>
              UDP Трансляции
            </NavLink>
        </span>
      ), 
    },
    {
      key: 'users',
      label:(
        <span className={styles.NavContainer}>
          <TeamOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/users" className={styles.NavHover}>Пользователи</NavLink>
        </span>
      ),
    },
    {
      key: 'new_user_requests',
      label:(
        <span className={styles.NavContainer}>
          <SolutionOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/users_requests" className={styles.NavHover}>
              Добавление пользователей
            </NavLink>
        </span>
      ),
    },
    {
      key: 'group_translations',
      label:(
        <span className={styles.NavContainer}>
          <UsergroupAddOutlined className={styles.NavHover}/>
          <Badge dot={typeof translationGroup === 'string'}>
            <NavLink to="/group_translations" className={styles.NavHover}>
                Групповые трансляции
              </NavLink>
            </Badge>
        </span>
      ),
    },
    {
      key: 'device_offline',
      label:(
        <span className={styles.NavContainer}>
          <BookOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/device_offline" className={styles.NavHover}>
              Оффлайн устройства
            </NavLink>
        </span>
      ),
    },
    {
      key: 'statistics',
      label:(
        <span className={styles.NavContainer}>
          <AreaChartOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/statistics" className={styles.NavHover}>
              Статистика
            </NavLink>
        </span>
      ),
    },
    {
      key: 'call_page',
      label:(
        <span className={styles.NavContainer}>
          <PhoneOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
          <NavLink to="/call_page" className={styles.NavHover}>Звонки</NavLink>
        </span>
      ),
    },
    // {
    //   key: 'webrtc_translations',
    //   icon: <BookOutlined />,
    //   label: <NavLink to="/webrtc_translations">Групповые звонки</NavLink>,
    // },
    {
      key: 'license_extension',
      label:(
        <span className={styles.NavContainer}>
          <AuditOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/license_extension" className={styles.NavHover}>
              Лицензии
            </NavLink>
        </span>
      ),
    },
    // {
    //   key: 'webrtc_translations',
    //   icon: <BookOutlined />,
    //   label: <NavLink to="/webrtc_translations">Групповые звонки</NavLink>,
    // },
    {
      key: 'localmachine_registration',
      label:(
        <span className={styles.NavContainer}>
          <DesktopOutlined className={styles.NavHover} style={{ paddingRight: 8}}/>
            <NavLink to="/localmachine_registration" className={styles.NavHover}>
              Локальные сервера
            </NavLink>
        </span>
      ),
    },
  ].filter((el) => {
    if (isAdmin) {
      return true;
    } else {
      if (el.key === '1_exit') {
        return true;
      } else {
        if (userState.responseAccesses) {
          return userState.responseAccesses[el.key];
        }
      }
    }
  });

  if (isMobile) {
    items.push({
      key: '1_exit',
      icon: <LogoutOutlined />,
      label: (
        <a href="/" onClick={logoutHandler}>
          {' '}
          Выйти{' '}
        </a>
      ),
    });
  }

  return isMobile ? (
    <Dropdown menu={{ items }} trigger={['click']} placement="bottomLeft">
      <div style={{ position: 'absolute', right: '20px' }}>
        <a onClick={(e) => e.preventDefault()}>
          <MenuOutlined />
        </a>
      </div>
    </Dropdown>
  ) : status === 'resolved' ? (
    <DrawerProfile menuItems={items} />
  ) : null;
};

export default NavBar;
